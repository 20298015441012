<template>
  <div class="background-main">
    <header-vue>
      <div class="page-label">
        <p>{{ active }}</p>
      </div>
    </header-vue>
    <div class="seperate-panel">
      <div class="flex-three">
        <side-panel-vue>
          <side-panel-list
          initial="settings"
          :list="list" />
        </side-panel-vue>
      </div>
      <div class="flex-seven">
        <div class="main-view">
          <div class="padding-both-side">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidePanelList from '../../../components/Admin/components/SidePanelList.vue'
import SidePanelVue from '../../../components/Common/SidePanel/SidePanel.vue'
import HeaderVue from '../../../components/Header/Header.vue'
import { userPanelList } from '../../../util/util'

export default {
  name: 'Settings',
  components: {
    SidePanelVue,
    HeaderVue,
    SidePanelList
  },
  data () {
    return {
      active: null,
      list: userPanelList
    }
  },
  watch: {
    $route (to, from) {
      if (to !== from) {
        this.getPageName(to.name)
      }
    }
  },
  mounted () {
    this.active = this.$route.name
    this.getPageName(this.$route.name)
  },
  methods: {
    getPageName (name) {
      const res = userPanelList.filter((obj) => obj.path === name)
      if (res.length) {
        this.active = res[0].alias
      }
    }
  }
}
</script>

<style lang="scss" src="../../MainPage/main.scss" scoped></style>
